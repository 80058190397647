import React from "react";
import { Toggle } from '@geist-ui/react'
import styled from 'styled-components'

const ToggleSwitch = styled(Toggle)`
height: 100% !important;
}
` 


export default ToggleSwitch;
